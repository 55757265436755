import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Progress from "../components/progress"
import {
  API_CALL_STATUSES,
  authAjax,
  getCookie,
  getRedirectPathFromUrl,
} from "../utils"
import WithCopy from "../components/withCopy"
import CenteredContainer from "../components/CenteredContainer"
import { FancyCardHeader } from "../components/FancyInput"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: API_CALL_STATUSES.PROGRESS,
      email: null,
    }
  }
  componentDidMount() {
    this.getUserDetails()
    var has_previous_login = getCookie("rio_loggedin_once")
    if (has_previous_login && has_previous_login === "yes")
      navigate(`/login/${getRedirectPathFromUrl()}`)
    else navigate(`/register/${getRedirectPathFromUrl()}`)
  }

  getUserDetails = () => {
    this.setState({
      status: API_CALL_STATUSES.PROGRESS,
    })
    authAjax({
      path: "user/authenticate",
      type: "GET",
      success: () => {
        authAjax({
          path: "user/info",
          type: "GET",
          success: res => {
            this.setState({
              email: res.data.email,
              status: API_CALL_STATUSES.SUCCESS,
            })
          },
          error: () => {
            this.setState({
              status: API_CALL_STATUSES.ERROR,
            })
          },
        })
      },
      error: () => {
        this.setState({
          status: API_CALL_STATUSES.ERROR,
        })
      },
    })
  }

  render() {
    const { status, email } = this.state
    if (status === API_CALL_STATUSES.ERROR) {
    }
    return (
      <Layout bgimage dark stickyFooter>
        {status === API_CALL_STATUSES.PROGRESS && <Progress />}
        <noscript>Please enable the Javascript on your browser.</noscript>
        {status === API_CALL_STATUSES.SUCCESS && (
          <CenteredContainer>
            <FancyCardHeader title="Manage Your Account"></FancyCardHeader>
            <p className="subText">
              You are logged in as
              <WithCopy value={email} />
              <br />
              <Link to={`/changePassword/${getRedirectPathFromUrl()}`}>
                Change Password
              </Link>{" "}
              / <Link to={`/logout/${getRedirectPathFromUrl()}`}>Logout</Link>
            </p>
          </CenteredContainer>
        )}
      </Layout>
    )
  }
}

export default IndexPage
